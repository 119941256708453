<template>
  <div class="view view--light" ref="view" v-resize="onResize">
    <v-row class="my-1" data-item-ignore>
      <v-col :cols="12" class="text-right py-0">
        <v-btn @click="toggleAside({ isClipped: true, isTemporary: false })" color="primary" text>
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span></v-btn
        >
      </v-col>
    </v-row>

    <v-card elevation="1" class="mt-4" v-if="customerAircraft" data-item-ignore>
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <div class="flex flex-grow-0 justify-end">
            <div class="flex flex-column justify-start align-end pa-4 text-right">
              <span class="text-h4">
                {{ latestFlightlogEntry.time_since_new }}
              </span>
              <span>
                {{ $t('label.timeSinceNew') }}
              </span>
            </div>

            <div class="flex flex-column justify-start align-end text-right pa-4">
              <span class="text-h4">
                {{ latestFlightlogEntry.landings_since_new }}
              </span>
              <span>
                {{ $t('label.landingsSinceNew') }}
              </span>
            </div>
          </div>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card elevation="1" class="mt-4" :loading="isLoading" v-if="customerAircraft">
      <v-card-text class="px-0 py-0">
        <TableFlightlog
          :height="tableHeight"
          :items="filteredLogItems"
          :columns="filters.columns.length ? filters.columns : tableColumns"
        />
      </v-card-text>
    </v-card>

    <v-row class="mt-4" v-else>
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <!-- Aside -->
    <v-navigation-drawer
      app
      :clipped="aside.isClipped"
      :temporary="aside.isTemporary"
      right
      v-model="aside.isVisible"
      :width="navDrawerWidth"
      :class="{ 'v-navigation-drawer--open': aside.isVisible }"
    >
      <div class="d-flex align-center justify-space-between py-2 pr-2 pl-4 bg-color-lighter-gray">
        <h3 class="body-1">{{ aside.headline }}</h3>
        <v-btn @click="aside.isVisible = false" icon depressed text> <v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
      <FilterBarBase
        :filterOptions="filterOptions"
        :table-columns="tableColumns"
        :selected-filter-options="filters"
        :selected-columns="filters.columns"
        @change="onFilterChange"
        class="mt-3 px-3 is-inside-nav-drawer"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_LOGS_MODULE } from '@/store/modules/customerAircraftFlightlogs';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';
import FilterBarBase from '@/components/FilterBar/FilterBarBase';
import TableFlightlog from '@/components/Table/TableFlightlog.vue';

export default {
  name: 'BoardbookIndex',

  components: {
    AlertNoAircraft,
    FilterBarBase,
    TableFlightlog
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`,
      customerAircraftsLogs: `${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/all`,
      latestFlightlogEntry: `${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/latest`,
      isLoading: `${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/isLoading`
    }),

    customerAircraftId() {
      return get(this.customerAircraft, 'id');
    },

    filterOptions() {
      const result = {
        tags: []
      };
      return result;
    },

    filteredLogItems() {
      let result = this.customerAircraftsLogs.slice();

      if (this.filters.query && this.filters.query.length) {
        const queryLower = this.filters.query.toLowerCase();
        result = this.customerAircraftsLogs.filter(item => {
          return (
            item.pilot.toLowerCase().includes(queryLower) ||
            item.location_to?.icao_code?.toLowerCase().includes(queryLower) ||
            item.location_to?.name?.toLowerCase().includes(queryLower) ||
            item.location_ldg?.icao_code?.toLowerCase().includes(queryLower) ||
            item.location_ldg?.name?.toLowerCase().includes(queryLower)
          );
        });
      }

      return result;
    },

    navDrawerWidth() {
      let result = '80vw';

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = '30vw';
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        result = '25vw';
      }

      return result;
    },

    salesname() {
      return get(this.customerAircraft, 'aircraft.easa_types.salesname');
    },

    variant() {
      return get(this.customerAircraft, 'aircraft.easa_types.variant');
    }
  },

  data() {
    return {
      aside: {
        component: '',
        headline: this.$t('Filters'),
        isClipped: false,
        isTemporary: true,
        isVisible: false
      },
      filters: {
        columns: [],
        query: ''
      },
      tableColumns: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Pilot',
          value: 'pilot'
        },
        {
          text: 'Co-Pilot',
          value: 'co-pilot'
        },
        {
          text: 'label.fromToIcao',
          value: 'fromToIcao'
        },
        {
          text: 'label.takeOff',
          value: 'takeOff'
        },
        {
          text: 'label.ldg',
          value: 'ldg'
        },
        {
          text: 'Landings',
          value: 'landings'
        },
        {
          text: 'label.flighttime',
          value: 'flighttime'
        },
        {
          text: 'label.timeSinceNew',
          value: 'time_since_new'
        },
        {
          text: 'label.landingsSinceNew',
          value: 'landings_since_new'
        },
        {
          text: 'label.hobbsEng1Start',
          value: 'hobbsEng1Start'
        },
        {
          text: 'label.hobbsEng1End',
          value: 'hobbsEng1End'
        },
        {
          text: 'label.hobbsTime',
          value: 'hobbsTime'
        },
        {
          text: 'Number',
          value: 'number'
        },
        {
          text: 'label.notes',
          value: 'notes'
        }
      ],
      tableHeight: ''
    };
  },

  mounted() {
    // Preselect columns
    this.filters.columns.push(this.tableColumns[0]);
    this.filters.columns.push(this.tableColumns[1]);
    this.filters.columns.push(this.tableColumns[3]);
    this.filters.columns.push(this.tableColumns[4]);
    this.filters.columns.push(this.tableColumns[5]);
    this.filters.columns.push(this.tableColumns[6]);
    this.filters.columns.push(this.tableColumns[7]);

    this.init();
  },

  methods: {
    async init() {
      if (this.customerAircraftId) {
        await this.$store.dispatch(`${CUSTOMER_AIRCRAFTS_LOGS_MODULE}/GET_FLIGHTLOGS`, {
          filter: {
            customer_aircraft: {
              _eq: this.customerAircraftId
            }
          }
        });
      }
    },

    onFilterChange(values) {
      this.filters = Object.assign({}, values);
      this.init();
    },

    toggleAside(params = {}) {
      // reset aside settings
      const { component = '', isClipped = false, isTemporary = true } = params;
      this.aside.component = component;
      this.aside.headline = this.$t('Filters');
      this.aside.isClipped = isClipped;
      this.aside.isTemporary = isTemporary;

      if (component === 'tableColumns') {
        this.aside.component = component;
        this.aside.isClipped = true;
        this.aside.isTemporary = false;
        this.aside.headline = this.$t('selectColumns');
      }

      this.aside.isVisible = !this.aside.isVisible;
    },

    async onResize() {
      await this.$nextTick(() => {
        const items = this.$refs['view'].querySelectorAll('[data-item-ignore]');

        let itemHeights = 0;
        if (items) {
          items.forEach(item => {
            itemHeights += item.clientHeight;
          });
        }

        const tableHeight = this.$refs['view'].clientHeight - itemHeights;
        this.tableHeight = `${tableHeight - 100}px`;
      });
    }
  },

  watch: {
    customerAircraftId() {
      this.init();
    },

    filteredLogItems() {
      setTimeout(() => {
        this.onResize();
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped></style>
