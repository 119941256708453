<template>
  <v-simple-table fixed-header :height="height" class="table table__flightlog">
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 1rem;">&nbsp;</th>
          <th v-if="isColumnVisible('number')">
            #
            <v-btn
              icon
              x-small
              :title="$t('changeSortOrder')"
              :class="getSortBtnClass(['number'], currentSort)"
              @click="onClickSort('number')"
            >
              <v-icon :class="getSortIconClass(['number'], currentSort)">mdi-arrow-down</v-icon>
            </v-btn>
          </th>
          <th v-if="isColumnVisible('date')">
            {{ $t('label.Date') }}
          </th>
          <th v-if="isColumnVisible('pilot')">
            {{ $t('Pilot') }}
          </th>
          <th v-if="isColumnVisible('co-pilot')">
            {{ $t('Copilot') }}
          </th>
          <th v-if="isColumnVisible('fromToIcao')">
            {{ $t('label.fromIcao') }} / <br />
            {{ $t('label.toIcao') }}
          </th>
          <th v-if="isColumnVisible('takeOff')">
            {{ $t('label.takeOff') }}
          </th>
          <th v-if="isColumnVisible('ldg')">
            {{ $t('label.ldg') }}
          </th>
          <th v-if="isColumnVisible('landings')">
            {{ $t('Landings') }}
          </th>
          <th v-if="isColumnVisible('flighttime')">
            {{ $t('label.flighttime') }}
          </th>
          <th v-if="isColumnVisible('time_since_new')">
            {{ $t('label.timeSinceNew') }}
          </th>
          <th v-if="isColumnVisible('landings_since_new')">
            {{ $t('label.landingsSinceNew') }}
          </th>
          <th v-if="isColumnVisible('hobbsEng1Start')">
            {{ $t('label.hobbsEng1Start') }}
          </th>
          <th v-if="isColumnVisible('hobbsEng1End')">
            {{ $t('label.hobbsEng1End') }}
          </th>
          <th v-if="isColumnVisible('hobbsTime')">
            {{ $t('label.hobbsTime') }}
          </th>
          <th style="min-width: 20rem" v-if="isColumnVisible('notes')">
            {{ $t('label.notes') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- No data -->
        <tr v-if="!items.length">
          <th colspan="9">
            <v-alert text dense type="info" class="mt-4">
              {{ $t('hint.noData') }}
            </v-alert>
          </th>
        </tr>

        <!-- All items -->
        <tr v-for="item in sortedItems" :key="item.id">
          <th>
            <v-icon v-if="item.type !== 'origin'" :title="$t(`label.${item.type}`)">mdi-airplane-edit</v-icon>
          </th>
          <td style="min-width: 4rem" v-if="isColumnVisible('number')">{{ item.number }}</td>
          <td style="min-width: 8rem" v-if="isColumnVisible('date')">{{ $d(new Date(item.date), 'short') }}</td>
          <td v-if="isColumnVisible('pilot')">{{ item.pilot }}</td>
          <td v-if="isColumnVisible('co-pilot')">{{ item.copilot }}</td>
          <td v-if="isColumnVisible('fromToIcao')">
            <div class="pt-1" :title="$t('label.fromIcao')">
              <v-icon class="mr-1" size="16">mdi-airplane-takeoff</v-icon>
              <span>{{ get(item, 'location_to.apt_shortname') }} </span>
              <span v-if="get(item, 'location_to.icao')">({{ get(item, 'location_to.icao') }})</span>
            </div>
            <div class="py-1" :title="$t('label.toIcao')">
              <v-icon class="mr-1" size="16">mdi-airplane-landing</v-icon>
              <span>{{ get(item, 'location_ldg.apt_shortname') }} </span>
              <span v-if="get(item, 'location_ldg.icao')">({{ get(item, 'location_ldg.icao') }})</span>
            </div>
          </td>
          <td :title="$d(getUTC(new Date(item.to_utc)), 'long') + ' (UTC)'" v-if="isColumnVisible('takeOff')">
            <span v-if="item.is_batch">&mdash;</span>
            <span v-else>{{ format(getUTC(new Date(item.to_utc)), 'HH:mm') }}</span>
          </td>
          <td :title="$d(getUTC(new Date(item.ldg_utc)), 'long') + ' (UTC)'" v-if="isColumnVisible('ldg')">
            <span v-if="item.is_batch">&mdash;</span>
            <span v-else>{{ format(getUTC(new Date(item.ldg_utc)), 'HH:mm') }}</span>
          </td>
          <td v-if="isColumnVisible('landings')">
            {{ item.cnt_ldg }}
          </td>
          <td v-if="isColumnVisible('flighttime')">
            {{ item.flight_time }}
          </td>
          <td v-if="isColumnVisible('time_since_new')">
            {{ item.time_since_new }}
          </td>
          <td v-if="isColumnVisible('landings_since_new')">
            {{ item.landings_since_new }}
          </td>
          <td v-if="isColumnVisible('hobbsEng1Start')">
            <span v-if="item.hobbs_eng1_start">
              {{ item.hobbs_eng1_start }}
            </span>
          </td>
          <td v-if="isColumnVisible('hobbsEng1End')">
            <span v-if="item.hobbs_eng1_end">
              {{ item.hobbs_eng1_end }}
            </span>
          </td>
          <td v-if="isColumnVisible('hobbsTime')">
            <span v-if="item.hobbs_time_eng1">
              {{ getDecimalToTime(item.hobbs_time_eng1) }}
            </span>
          </td>
          <td v-if="isColumnVisible('notes')">
            <span>{{ item.notes }}</span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { format, addMinutes } from 'date-fns';
import { get, sortBy } from 'lodash';
import { getSortBtnClass, getSortIconClass } from '@/services/utils';

export default {
  name: 'TableFlightlog',

  props: {
    columns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    height: {
      type: String,
      required: false,
      default: ''
    },

    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    columnValues() {
      const result = [];
      this.columns.forEach(column => {
        const { value } = column;
        if (value) {
          result.push(value);
        }
      });
      return result;
    },

    sortedItems() {
      let result = this.items.slice();
      const prop = get(this.currentSort, 'prop');
      const sortOrder = get(this.currentSort, 'value');
      if (this.items.length && prop && sortOrder) {
        result = sortBy(this.items, item => {
          return item[prop];
        });
      }

      if (sortOrder === 'desc') {
        result.reverse();
      }
      return result;
    }
  },

  data() {
    return {
      currentSort: {
        text: 'sort.title.asc',
        value: 'asc',
        prop: 'title'
      }
    };
  },

  methods: {
    format,
    get,
    getSortBtnClass,
    getSortIconClass,

    getUTC(date) {
      return addMinutes(date, date.getTimezoneOffset());
    },

    getDecimalToTime(value) {
      const toMinutes = Math.floor(Math.round(value * 1440));
      const n = new Date(0, 0);
      n.setMinutes(+toMinutes);
      return n.toTimeString().slice(0, 5);
    },

    isColumnVisible(value) {
      return this.columnValues.includes(value);
    },

    onClickSort(propName) {
      const currentDirection = get(this.currentSort, 'value', 'asc');
      const direction = currentDirection === 'asc' ? 'desc' : 'asc';
      this.currentSort = {
        text: `sort.${direction}`,
        value: `${direction}`,
        prop: `${propName}`
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
